import React from "react"
import { Link } from "gatsby"
import { Articles } from "components/BlogItems"
import Page, { Wrapper, Main, Side } from "components/Page"
import Title from "components/Title"
import Sidebar from "components/Sidebar"
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Page>
      <Title small back={"/"} />
      <Wrapper>
        <Main>
          <h1>Articles</h1>
          <Articles long edges={edges} />
        </Main>
        <Side>
          <Sidebar />
        </Side>
      </Wrapper>
    </Page>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { publish: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
